import React from "react";
import styled from "styled-components";
import clock from "../api/clock";

const CalendarDate = styled.div`
  display: inline-block;
  margin-right: 10px;
  width: 70px;
  height: 70px;
`;

const CalendarHeader = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  background: rgb(245,0,0);
  background: linear-gradient(180deg, rgba(245,0,0,1) 35%, rgba(189,6,6,1) 100%);
  border-radius: 3px 3px 0 0;
`;

const CalendarDateNumber = styled.div`
  padding: 5px;
  font-size: 24px;
  text-align: center;
  border: solid 1px #999;
  border-top: none;
`;

export default function Calendar(props) {
  const date = clock(props.date, 'YYYY-MM-DD');

  const format = props.month ? 'MMM' : 'ddd';

  return (
    <CalendarDate>
      <CalendarHeader>{date.format(format)}</CalendarHeader>
      <CalendarDateNumber>{date.format('DD')}</CalendarDateNumber>
    </CalendarDate>
  );
}