import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faConciergeBell,
  faFlag,
  faInfoCircle,
  faLeaf,
  faParking,
  faPaw,
  faSpa,
  faSwimmer,
  faUtensils,
  faWifi
} from "@fortawesome/free-solid-svg-icons";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {Col, Row} from "react-bootstrap";
import {Box, SubTitle, Title} from "./UI";
import ExternalReview from "./ExternalReview";
import PhotoCarousel from "./PhotoCarousel";

const AmenitiesList = styled.ul`
  
`;

const AmenityItem = styled.li`
  
`;

export default function Hotel(props) {
  return (
    <Box>
      <Title><FontAwesomeIcon icon={faConciergeBell} /> Hotel</Title>
      <h5>{props.hotel.name} {props.hotel.website && <small><a href={props.hotel.website} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /></a></small>}</h5>

        <PhotoCarousel photos={props.hotel.photos} />
      {props.hotel.amenities && <Box>
        <Row>
          {props.hotel.amenities.outdoors && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faLeaf} /> Ar Livre</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.outdoors)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.pets && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faPaw} /> Animais de Estimação</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.pets)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.wellness && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faSpa} /> Bem Estar</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.wellness)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.activities && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faFlag} /> Atividades</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.activities)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.pool && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faSwimmer} /> Piscina</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.pool)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.food && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faUtensils} /> Alimentação</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.food)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.general && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faInfoCircle} /> Geral</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.general)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.parking && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faParking} /> Estacionamento</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.parking)}
              </AmenitiesList>
            </Box>
          </Col>}
          {props.hotel.amenities.internet && <Col xs={12} sm={6} md={6} lg={4}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faWifi} /> Internet</SubTitle>
              <AmenitiesList>
                {toListItem(props.hotel.amenities.internet)}
              </AmenitiesList>
            </Box>
          </Col>}
        </Row>
      </Box>}
      {props.hotel.reviews && props.hotel.reviews.length > 0 && <Box>
        <Row>
          {props.hotel.reviews.map(review => <ExternalReview review={review} />)}
        </Row>
      </Box>}
    </Box>);
}

function toListItem(amenities) {
  return amenities.map(amenity =>
    <AmenityItem key={amenity}>
      {amenity}
    </AmenityItem>
  );
}