import React, {useEffect, useState} from "react";
import {Heading3} from "./Heading";
import Trip from "./Trip";
import tripsList from "../api/trip";
import clock from "../api/clock";

export default function Trips() {
  const [trips, setTrips] = useState([]);
  const [tripsByYear, setTripsByYear] = useState({});

  useEffect(() => setTrips(Object.keys(tripsList).map(key => tripsList[key])), [tripsList]);
  useEffect(() => setTripsByYear(groupByYear(trips)), [trips]);

  return Object.keys(tripsByYear).sort((yearA, yearB) => yearB - yearA).map(year =>
    <div key={year}>
      <Heading3>{year}</Heading3>
      {tripsByYear[year].map(trip => <Trip trip={trip} />)}
    </div>
  );
}

function groupByYear(trips) {
  const tripsByYear = {};

  trips.forEach(trip => {
    const year = clock(trip.date, 'YYYY-MM-DD').format('YYYY');

    const list = tripsByYear[year] || [];
    list.push(trip);
    tripsByYear[year] = list;
  });

  return tripsByYear;
}