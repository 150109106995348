import React from "react";
import Page from "../component/Page";
import {title} from "../component/Website";
import {Carousel} from "react-bootstrap";

import placeFozDoIguacu from "../static/img/place/foz-do-iguacu/waterfall.jpg";
import placeBonito from "../static/img/place/bonito/praca-das-peraputangas.jpg";
import placeMaragogi from "../static/img/place/maragogi/beach.jpg";
import placeCuritiba from "../static/img/place/curitiba/bosque-do-alemao.jpg";
import Trips from "../component/Trips";

export default function HomePage() {
  title();

  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={placeFozDoIguacu}
            alt="Foz do Iguacu, PR"
          />
          <Carousel.Caption>
            <h3>Foz do Iguacu, PR</h3>
            <p>Credito Zig Koch - MTUR</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={placeBonito}
            alt="Bonito, MS"
          />
          <Carousel.Caption>
            <h3>Bonito, MS</h3>
            <p>Flávio André - MTUR</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={placeMaragogi}
            alt="Maragogi, AL"
          />
          <Carousel.Caption>
            <h3>Maragogi, AL</h3>
            <p>Marco Ankosqui/MTur</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={placeCuritiba}
            alt="Curitiba, PR"
          />
          <Carousel.Caption>
            <h3>Curitiba, PR</h3>
            <p>Renato Soares - MTUR</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Page>
        <Trips />
      </Page>
    </div>
  );
}