import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {Row} from "react-bootstrap";
import Price from "./Price";
import ExternalReview from "./ExternalReview";
import {Box, SubTitle} from "./UI";
import PhotoCarousel from "./PhotoCarousel";

export default function Restaurant(props) {
  return (
    <div>
      <SubTitle>{props.restaurant.name} {props.restaurant.website &&
        <small><a href={props.restaurant.website} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
          icon={faExternalLinkAlt}/></a></small>}</SubTitle>
      {props.restaurant.photos && props.restaurant.photos.length > 0 &&
        <PhotoCarousel photos={props.restaurant.photos}/>}
      <Box>
        {props.restaurant.text.split('\n').map(line => <p key={line}>{line}</p>)}
      </Box>
      {props.restaurant.prices && <Price prices={props.restaurant.prices} optional={true}/>}
      {props.restaurant.reviews && props.restaurant.reviews.length > 0 && <Box>
        <Row>
          {props.restaurant.reviews.map(review => <ExternalReview review={review}/>)}
        </Row>
      </Box>}
    </div>
  );
}