import tripSerraGaucha2022 from "../static/data/trips/serras-gauchas-2022.json";
import costelao2022 from "../static/data/trips/costelao-fogo-de-chao-2022.json";
import caldasNovas2023 from "../static/data/trips/caldas-novas-2023.json";
import mariaFumacao2022 from "../static/data/trips/maria-fumaca-2022.json";

const trips = {
  'caldas-novas_2023-01-14': caldasNovas2023,
  'costelao-fogo-de-chao_2022-11-27': costelao2022,
  'maria-fumaca_2022-10-22': mariaFumacao2022,
  'serras-gauchas_2022-09-24': tripSerraGaucha2022
}

export default trips;
