import React from "react";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faFacebookMessenger, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";

const FooterBar = styled.footer`
    margin-top: 50px;
    padding: 40px 10px;
    padding-bottom: 60px;
    background-color: #f9f9f9;
`;

const Copyright = styled.div`
  margin: 15px 0;
  text-align: center;
  font-size: 12px;  
`;

const FooterLinks = styled.div`
  text-align: center;
  font-size: 14px;
`;

const FooterLink = styled(Link)`
  padding: 0 10px;
  border-right: solid 1px #ccc;
  
  &:last-child {
    border-right: none;
  } 
`;

const SocialBar = styled.div`
  text-align: center;
`;

const Social = styled.a`
  margin-left: 15px;
  font-size: 32px;
  color: #169be9;
  
  &:hover {
    color: #0479c7;
  }
`;

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <FooterBar>
      <Container>
        <SocialBar>
          <Social href="https://facebook.com/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </Social>
          <Social href="https://instagram.com/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </Social>
          <Social href="https://m.me/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookMessenger} />
          </Social>
          <Social href="https://wa.me/5519983149200" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} />
          </Social>
        </SocialBar>
        <Copyright>© {year} Poc Viagens. Todos os direitos reservados.</Copyright>
        <FooterLinks>
          <FooterLink to="/contato">Contato</FooterLink>
          <FooterLink to="/quem-somos-nos">Quem somos nós?</FooterLink>
        </FooterLinks>
      </Container>
    </FooterBar>
  );
}