import styled from "styled-components";

const Heading2 = styled.h2`
  font-family: Caveat;
  font-size: 42px;
  color: #ff9603;
`;

const Heading3 = styled.h3`
  font-family: Caveat;
  font-size: 32px;
  color: #169be9;
`;

export { Heading2, Heading3 };