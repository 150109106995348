import React from "react";
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

const ExternalReviewBox = styled.div`
  margin-top: 15px;
  padding: 10px;
  background-color: #fcfcfc;
  border: solid 1px #e4e4e4;
  border-top: solid 10px #e4e4e4;
`;

const ExternalReviewText = styled.p`
  margin: 0;
  font-family: Caveat;
  color: #666;
`;

const ExternalReviewAuthor = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: right;
`;

const ExternalReviewSource = styled.p`
  margin-bottom: 0;
  font-style: italic;
  font-size: 12px;
`;

export default function ExternalReview(props) {
  return (
    <Col xs={12} md={6} lg={4} key={props.review.text}>
      <ExternalReviewBox>
        <ExternalReviewText>"{props.review.text}"</ExternalReviewText>
        <ExternalReviewAuthor><FontAwesomeIcon icon={faUser} /> {props.review.author}</ExternalReviewAuthor>
        <ExternalReviewSource>{props.review.source}</ExternalReviewSource>
      </ExternalReviewBox>
    </Col>
  );
}