import React from "react";
import styled from "styled-components";

const Frame = styled.div`
  border-radius: 5px;
  border: solid 1px #eee;
  box-shadow: 2px 2px 3px #ccc;
  
  && > img {
    width: 100%;
    border-radius: 5px;
  }
`;

export function PhotoFrame(props) {
  return (
    <Frame>
      <img src={props.src} alt={props.alt} />
    </Frame>
  );
}