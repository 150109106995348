import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {Tab, Tabs} from "react-bootstrap";
import {faImages, faMap} from "@fortawesome/free-regular-svg-icons";
import {Box, SubTitle} from "./UI";
import PhotoCarousel from "./PhotoCarousel";

export default function Sightseeing(props) {
  return (
    <Box>
      <SubTitle>{props.sight.name} {props.sight.website && <small><a href={props.sight.website} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /></a></small>}</SubTitle>
      <p><FontAwesomeIcon icon={faMapMarkedAlt} /> {props.sight.location}</p>
      <Tabs defaultActiveKey="photos" id={`props.sight-${props.sight.name}`}>
        <Tab eventKey="photos" title={<span><FontAwesomeIcon icon={faImages} /> Fotos</span>}>
          <PhotoCarousel photos={props.sight.photos} />
        </Tab>
        {props.sight.location && <Tab eventKey="map" title={<span><FontAwesomeIcon icon={faMap} /> Mapa</span>}>
          <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBdSH-A40U0vFr5fpnsQFYq2cN6PN285FA&maptype=satellite&zoom=18&q=${props.sight.location}`}
            frameBorder="0" style={{border:0, width: '100%', minHeight: '350px'}} allowFullScreen="" aria-hidden="false"
            tabIndex="0" />
        </Tab>}
      </Tabs>
    </Box>
  );
}