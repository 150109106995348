import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./component/Header";
import PageRouter from "./PageRouter";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from "./component/Footer";

export default function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <PageRouter />
        <Footer />
      </Router>
    </div>
  );
}
