import React from "react";
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import Calendar from "./Calendar";
import {Link} from "react-router-dom";
import {PhotoFrame} from "./PhotoFrame";
import {Heading3} from "./Heading";

const TripPhotoFrame = styled.div`
  margin-bottom: 20px;
`;

export default function Trip(props) {
  return (
    <Row key={props.trip.date}>
      <Col xs={2} lg={1}>
        <Calendar date={props.trip.date} month />
      </Col>
      <Col xs={10} md={5} lg={4}>
        <TripPhotoFrame>
          <Link to={`/viagem/${props.trip.url}`}>
            <PhotoFrame src={props.trip.banner} alt={props.trip.title} />
          </Link>
        </TripPhotoFrame>
      </Col>
      <Col xs={12} md={5} lg={7}>
        <Heading3><Link to={`/viagem/${props.trip.url}`}>{props.trip.title}</Link></Heading3>
        <p>{props.trip.ad.text}</p>
      </Col>
    </Row>
  );
}