import React from "react";
import styled from "styled-components";

import elderlyIcon from "../static/img/icon/elderly.png";
import familyIcon from "../static/img/icon/family.png";
import groupIcon from "../static/img/icon/group.png";
import kidsIcon from "../static/img/icon/kids.png";
import pairIcon from "../static/img/icon/pair.png";
import parentsWithKidsIcon from "../static/img/icon/parents-with-kids.png";
import {OverlayTrigger, Popover} from "react-bootstrap";

const StyledGroup = styled.div`
  display: inline-block;
`;

function Group(props) {
  return (
    <StyledGroup>
      <OverlayTrigger placement="bottom" overlay={
        <Popover id={props.title}>
          <Popover.Content>{props.title}</Popover.Content>
        </Popover>
      }>
        <img src={props.icon} alt={props.title} title={props.title} />
      </OverlayTrigger>
    </StyledGroup>
  );
}

export function ElderlyGroup() {
  return <Group icon={elderlyIcon} title="Idosos" />
}
export function FamilyGroup() {
  return <Group icon={familyIcon} title="Famílias" />
}
export function GroupGroup() {
  return <Group icon={groupIcon} title="Grupos" />
}
export function KidsGroup() {
  return <Group icon={kidsIcon} title="Crianças" />
}
export function PairGroup() {
  return <Group icon={pairIcon} title="Casais" />
}
export function ParentsWithKidsGroup() {
  return <Group icon={parentsWithKidsIcon} title="Crianças acompanhadas" />
}

export default {
  ElderlyGroup,
  FamilyGroup,
  GroupGroup,
  KidsGroup,
  PairGroup,
  ParentsWithKidsGroup
}