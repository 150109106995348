import React from "react";
import Page from "../component/Page";
import Trips from "../component/Trips";

export default function TripListPage() {
  return (
    <Page title="Viagens">
      <Trips />
    </Page>
  );
}