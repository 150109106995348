import React, {useEffect, useState} from "react";
import styled from "styled-components";
import clock from "../api/clock";
import Page from "../component/Page";
import Price from "../component/Price";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faThumbsDown, faThumbsUp} from "@fortawesome/free-regular-svg-icons";
import {
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faLongArrowAltRight,
  faMapSigns,
  faPlane,
  faTimesCircle,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";
import Calendar from "../component/Calendar";
import {Jumbotron} from "../component/Components";
import {ElderlyGroup, FamilyGroup, PairGroup} from "../component/Group";
import trips from "../api/trip";
import {PhotoFrame} from "../component/PhotoFrame";
import Hotel from "../component/Hotel";
import {Box, SubTitle, Title} from "../component/UI";
import Sightseeing from "../component/Sightseeing";
import Flight from "../component/Flight";
import Schedule from "../component/Schedule";
import Restaurant from "../component/Restaurant";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const IncludingIcon = styled(FontAwesomeIcon)`
  color: #007700;
`;

const NotIncludingIcon = styled(FontAwesomeIcon)`
  color: #aa0000;
`;

const ArrowRight = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 32px;
`;

const TripDate = styled.h5`
  & small {
    font-style: italic;
    font-size: 70%;
    color: #ff9603;
  }
`;

const Source = styled.p`
  font-style: italic;
  text-align: right;
`;

const RecommendedTo = styled.p`
  font-family: Caveat;
  text-align: right;
`;

const Banner = styled.div`
  margin: 20px 0;

  & img {
    width: 100%;
  }
`;

export default function TripPage(props) {
  const [trip, setTrip] = useState();
  const [days, setDays] = useState(1);
  const [showFullSchedule, setShowFullSchedule] = useState(false);

  useEffect(() => setTrip(trips[`${props.place}_${props.date}`]), [props.place, props.date]);
  useEffect(() => trip && setDays(clock(trip.to, 'YYYY-MM-DD').diff(clock(trip.from, 'YYYY-MM-DD'), 'days')), [trip, setDays]);

  return (
    <div>
      {trip && <Page title={trip.title}>
        <Row>
          <Col>
            <TripDate>{clock(trip.date, 'YYYY-MM-DD').format('LL')} <small>({clock(trip.date, 'YYYY-MM-DD').fromNow()})</small></TripDate>
          </Col>
          <Col>
            <div style={{textAlign: 'right'}}>
              <div style={{display: 'inline-block', textAlign: 'center'}}>
                <Calendar date={trip.date} month />
                {trip.to && <ArrowRight icon={faLongArrowAltRight} />}
                {trip.to && <Calendar date={trip.to} month />}
                {trip.to && <p>{days} dia{days > 1 && 's'}</p>}
              </div>
            </div>
          </Col>
        </Row>
        {trip.banner && <Banner><PhotoFrame src={trip.banner} alt={trip.title} /></Banner>}
        {trip.ad && <Jumbotron>
          {trip.ad.subtitle && <h4>{trip.ad.subtitle}</h4>}
          {trip.ad.title && <h3>{trip.ad.title}</h3>}
          <p>{trip.ad.text}</p>
          {trip.ad.source && <Source><a href={trip.ad.source.link} target="_blank" rel="noopener noreferrer">{trip.ad.source.label}</a></Source>}
        </Jumbotron>}
        {trip.recommendedTo && trip.recommendedTo.length > 0 && <Box>
          <RecommendedTo>Recomendado para {trip.recommendedTo.map(group => toGroup(group))}</RecommendedTo>
        </Box>}
        {trip.sightseeing && trip.sightseeing.length > 0 && <Box>
          <Title><FontAwesomeIcon icon={faMapSigns} /> Explore</Title>
          {trip.sightseeing.map(sightseeing => <Sightseeing sight={sightseeing} />)}
        </Box>}
        {trip.restaurants && trip.restaurants.length > 0 && <Box>
          <Title><FontAwesomeIcon icon={faUtensils} /> Saboreie</Title>
          {trip.restaurants.map(restaurant => <Restaurant restaurant={restaurant} />)}
        </Box>}
        {trip.hotels && trip.hotels.map(hotel => <Hotel hotel={hotel} />)}
        {trip.flights && trip.flights.length > 0 && <Box>
          <Title><FontAwesomeIcon icon={faPlane} /> Aéreo</Title>
          {trip.flights.map(flight => <Flight flight={flight} />)}
        </Box>}
        {trip.schedule && <Box>
          <Title><FontAwesomeIcon icon={faCalendarCheck} /> Programação</Title>
          <p>A programação poderá ser alterada para proporcionar uma melhor experiência aos passageiros.</p>
          {!showFullSchedule && <Button variant="secondary" onClick={() => setShowFullSchedule(true)} size="sm"><FontAwesomeIcon icon={faCaretDown} /> Ver programação completa</Button>}
          {showFullSchedule && <Button variant="secondary" onClick={() => setShowFullSchedule(false)} size="sm"><FontAwesomeIcon icon={faCaretUp} /> Esconder programação completa</Button>}
          {showFullSchedule && trip.schedule.map(item => <Schedule item={item} />)}
        </Box>}
        <Row>
          {trip.including && trip.including.length > 0 && <Col xs={12} md={6}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faThumbsUp} /> Incluso</SubTitle>
              <List>
                {toList(trip.including, <IncludingIcon icon={faCheckCircle} />)}
              </List>
            </Box>
          </Col>}
          {trip.notIncluding && trip.notIncluding.length > 0 && <Col xs={12} md={6}>
            <Box>
              <SubTitle><FontAwesomeIcon icon={faThumbsDown} />  Não Incluso</SubTitle>
              <List>
                {toList(trip.notIncluding, <NotIncludingIcon icon={faTimesCircle} />)}
              </List>
            </Box>
          </Col>}
        </Row>
        <Price prices={trip.prices} />
      </Page>}
    </div>
  );
}

function toGroup(name) {
  switch (name.toUpperCase()) {
    case 'IDOSOS':
      return <ElderlyGroup />;
    case 'CASAIS':
      return <PairGroup />;
    case 'FAMILIAS':
      return <FamilyGroup />;
  }
}

function toList(list, symbol) {
  return list.map(item =>
    <li key={item}>{symbol} {item}</li>
  );
}