import React from "react";
import {Carousel} from "react-bootstrap";

const URL_REGEX = /https?:\/\/([^/]+)\.*/

export default function PhotoCarousel(props) {
  const source = URL_REGEX.exec(props.photos[0])[1];

  return (
    <Carousel>
      {props.photos.map(photo =>
        <Carousel.Item key={photo}>
          <img
            className="d-block w-100"
            src={photo}
            alt={photo}
          />
          <Carousel.Caption>
            <a href={photo} target="_blank" rel="noopener noreferrer">fonte: {source}</a>
          </Carousel.Caption>
        </Carousel.Item>)
      }
    </Carousel>
  );
}