import React from "react";
import styled from "styled-components";
import money from "../api/money";

const PriceBox = styled.div`
  margin: 30px 0;
  padding: 15px;
  background-color: ${props => props.optional ? 'transparent' : 'rgba(0, 150, 0, 0.1)'};
  border-radius: 5px;
  border: ${props => props.optional ? 'none' : 'solid 1px #d4e1d1'};
  font-size: 21px;
`;

const PriceTag = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-weight: 400;
  text-align: right;
  width: 130px;
`;

export default function Price(props) {
  const prices = props.prices.map(price =>
    <div key={price.description}>
      <PriceTag>{price.price === 0 ? 'Gratuito' : money.format(price.price)}</PriceTag> {price.description}
    </div>
  );

  return (
    <PriceBox optional={props.optional}>
      {prices}
    </PriceBox>
  );
}