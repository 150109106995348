import React from "react";
import styled from "styled-components";
import {Jumbotron as BootstrapJumbotron} from "react-bootstrap";

const StyledJumbotron = styled(BootstrapJumbotron)`
  margin-top: 40px;
  background-color: #991e66;
  color: #fff;
  
  & h4 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #ffd400;
  }
  
  & h3 {
    font-size: 38px;
    font-weight: 300;
  }
  
  & p {
    font-size: 18px;
    color: #fff;
  }
`;

export function Jumbotron(props) {
  return <StyledJumbotron {...props} />;
}

export default {Jumbotron};