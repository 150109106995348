import styled from "styled-components";

export const Title = styled.h3`
  margin-top: 40px;
`;

export const Box = styled.div`
  margin-top: 20px;
`;

export const SubTitle = styled.h5`
  font-family: Caveat;
  color: #169be9;
`;