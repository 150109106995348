import React from "react";
import {Col, Row} from "react-bootstrap";
import Calendar from "./Calendar";
import {Event, Timeline} from "./Timeline";
import {Box} from "./UI";

export default function Schedule(props) {
  return (
    <Box>
      <Row>
        <Col xs={2} md={2} lg={1}>
          <Calendar date={props.item.date} />
        </Col>
        <Col xs={10} md={10} lg={11}>
          <Timeline>
            {props.item.events.map(event => <Event key={event.time} title={event.time} text={event.text} />)}
          </Timeline>
        </Col>
      </Row>
    </Box>
  );
}