import React from "react";
import {Col, Row} from "react-bootstrap";
import Calendar from "./Calendar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlane, faPlaneArrival, faPlaneDeparture} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {Box, SubTitle} from "./UI";

export default function Flight(props) {
  return (
    <Box>
      <Row>
        <Col xs={2} md={2} lg={1}>
          <Calendar date={props.flight.date} />
        </Col>
        <Col xs={10} md={10} lg={11}>
          <Row>
            <Col xs={12} md={6}>
              <SubTitle><FontAwesomeIcon icon={faPlaneDeparture} /> {props.flight.fromAirportName} ({props.flight.fromAirport})</SubTitle>
              <p><FontAwesomeIcon icon={faClock} /> Decolagem prevista: {props.flight.departure}</p>
              <p><FontAwesomeIcon icon={faClock} /> Check in: {props.flight.checkIn}</p>
            </Col>
            <Col xs={12} md={6}>
              <SubTitle><FontAwesomeIcon icon={faPlaneArrival} /> {props.flight.toAirportName} ({props.flight.toAirport})</SubTitle>
              <p><FontAwesomeIcon icon={faClock} /> Chegada prevista: {props.flight.arrival}</p>
              {props.flight.company && <p><FontAwesomeIcon icon={faPlane} /> {props.flight.company}</p>}
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </Box>
  );
}