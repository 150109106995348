import React from "react";
import styled from "styled-components";
import Page from "../component/Page";
import {Col, Row} from "react-bootstrap";

import photo from "../static/img/cida.jpg";
import {Heading2, Heading3} from "../component/Heading";
import {Link} from "react-router-dom";

const PhotoBox = styled.div`
  text-align: center;
`;

const Photo = styled.img`
  margin-bottom: 10px;
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
  border: solid 3px #666;
`;

const Name = styled.h3`
  color: #333;
`;

const Title = styled.h5`
  font-family: Caveat;
  color: #169be9;
`;

export default function AboutPage() {
  return (
    <Page title="Quem somos nós?">
      <Row>
        <Col sm={12} md={8}>
          <p>
            Somos uma empresa familiar que está há mais de 20 anos organizando viagens pelo Brasil.
          </p>
          <Heading3>História</Heading3>
          <p>
            Nossa história começou no ano de 1997, quando a Cida, a fundadora da empresa, gostaria de levar seu
            filho para o Beto Carrero World em Santa Catarina, porém ela não tinha condições financeiras para isso.
          </p>
          <p>
            A forma que ela encontrou para realizar o que parecia impossível foi organizar uma excursão para lá,
            e mesmo sem nenhuma experiência nessa área, ela conseguiu não só levar seu filho para o parque, mas outras
            trinta pessoas.
          </p>
          <p>
            Aquela viagem foi tão bem sucedida, que desde então ela não parou mais, ela descobriu que poderia ajudar
            outras pessoas a realizarem seus sonhos e explorar novos lugares.
          </p>
          <p>
            Em 2020 o mundo mudou, então decidimos que é a hora de expandir, criamos o site <Link to="/">ArrumeSuaMala.com</Link> e vamos
            usar a internet para podermos levar muito mais pessoas para conhecer o Brasil.
          </p>
        </Col>
        <Col sm={12} md={4}>
          <PhotoBox>
            <Photo src={photo} alt="Cida" title="Cida" />
            <Name>Cida</Name>
            <Title>Diretora de Operações</Title>
          </PhotoBox>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <Heading2>Visão</Heading2>
          <p>
            Levar o brasileiro a explorar e presenciar as belezas do nosso país.
          </p>
          <Heading2>Missão</Heading2>
          <p>
            Proporcionar viagens inesquecíveis de forma acessível e descomplicada.
          </p>
          <Heading2>Valores</Heading2>
          <p>
            Respeitar os passageiros, preservar a natureza e os patrimônios históricos da nossa Terra.
          </p>
        </Col>
      </Row>
    </Page>
  );
}