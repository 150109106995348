import React, {Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import Header from "./component/Header";
import Page from "./component/Page";
import HomePage from "./page/HomePage";
import AboutPage from "./page/AboutPage";
import ContactPage from "./page/ContactPage";
import TripPage from "./page/TripPage";
import TripListPage from "./page/TripListPage";

export default function PageRouter() {
  return (
    <Switch>
      <Route path="/quem-somos-nos">
        <Suspense fallback={<Loading/>}>
          <AboutPage/>
        </Suspense>
      </Route>
      <Route path="/contato">
        <Suspense fallback={<Loading/>}>
          <ContactPage />
        </Suspense>
      </Route>
      <Route path="/viagem/:place/:date" render={({match}) =>
        <Suspense fallback={<Loading/>}>
          <TripPage place={match.params.place} date={match.params.date} />
        </Suspense>
      } />
      <Route path="/viagem">
        <Suspense fallback={<Loading/>}>
          <TripListPage/>
        </Suspense>
      </Route>
      <Route path="/">
        <Suspense fallback={<Loading/>}>
          <HomePage/>
        </Suspense>
      </Route>
    </Switch>
  );
}

function Loading() {
  return (
    <div>
      <Header/>
      <Page title="Carregando..."/>
    </div>
  );
}