import React from "react";
import styled from "styled-components";
import Page from "../component/Page";
import {Heading3} from "../component/Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faFacebookMessenger, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Icon = styled(FontAwesomeIcon)`
  color: #666;
`;

const Text = styled.p`
  margin-bottom: 25px;
`;

export default function ContactPage() {
  return (
    <Page title="Contato">
      <Text>Nós temos vários canais de acesso, estamos presentes nas principais redes sociais, fique a vontade para entrar em contato conosco da forma que for mais fácil para você.</Text>
      <Heading3>Instagram</Heading3>
      <Text><Icon icon={faInstagram} /> <a href="https://instagram.com/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">ArrumeSuaMala</a></Text>
      <Heading3>Facebook</Heading3>
      <Text><Icon icon={faFacebook} /> <a href="https://facebook.com/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">ArrumeSuaMala</a></Text>
      <Heading3>Messenger</Heading3>
      <Text><Icon icon={faFacebookMessenger} /> <a href="https://m.me/ArrumeSuaMala" target="_blank" rel="noopener noreferrer">ArrumeSuaMala</a></Text>
      <Heading3>WhatsApp</Heading3>
      <Text><Icon icon={faWhatsapp} /> <a href="https://wa.me/5519983149200" target="_blank" rel="noopener noreferrer">+55 (19) 98314-9200</a></Text>
      <Heading3>Telefone</Heading3>
      <Text><Icon icon={faPhone} /> <a href="tel:+5519983149200">+55 (19) 98314-9200</a></Text>
      <Heading3>Email</Heading3>
      <Text><Icon icon={faEnvelope} /> <a href="mailto:contato@arrumesuamala.com">contato@ArrumeSuaMala.com</a></Text>
    </Page>
  );
}