import React from "react";
import styled from "styled-components";

const TimelineBox = styled.div`
  
  
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #ff9603;
    top: 0;
    bottom: 0;
    left: 125px;
    margin-left: -3px;
  }
`;

const EventBox = styled.div`
  display: flex;
  position: relative;
  margin-top: 5px;
  margin-bottom: 50px;
`;

const EventTitle = styled.div`
  padding: 10px;
  max-width: 70px;
  width: 70px;
  text-align: right;
  margin-right: 20px;
  
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 98px;
    width: 21px;
    height: 21px;
    background-color: #fff;
    border: 6px solid #ff9603;
    border-radius: 50%;
    z-index: 1;
  }
`;

const EventText = styled.div`
  flex-grow: 1;
  margin-left: 50px;
  padding: 10px;
  background-color: #fafafa;
  border: solid 1px #e9e9e9;
`;

export function Timeline(props) {
  return (
    <TimelineBox>
      {props.children}
    </TimelineBox>
  );
}

export function Event(props) {
  return (
    <EventBox>
      <EventTitle>{props.title}</EventTitle>
      <EventText>{props.text}</EventText>
    </EventBox>
  );
}

export default {Timeline, Event};