import React from "react";
import styled from "styled-components";
import {Nav, Navbar} from "react-bootstrap";

import logo from "../static/img/logo-md.png";
import {Link} from "react-router-dom";

const HeaderBar = styled.header`
    box-shadow: 0 1px 0 #efefef;
`;

const MainNavbar = styled(Navbar)`
  background-color: #fff;
`;

export default function Header() {
  return (
    <HeaderBar>
      <MainNavbar expand="md" fixed="top">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Arrume sua mala e vamos viajar"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/">Página Inicial</Nav.Link>
            <Nav.Link as={Link} to="/viagem/">Viagens</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </MainNavbar>
    </HeaderBar>
  );
}