import React from "react";
import styled from "styled-components";
import {Container} from "react-bootstrap";

const Title = styled.h1`
  font-family: Caveat;
  font-size: 52px;
  color: #288d1f;
`;

const Padding = styled.div`
  padding-top: 70px;
`;

export default function Page(props) {
  return (
    <div>
      <Container>
        <Padding />
        <Title>{props.title}</Title>
        {props.children}
      </Container>
    </div>
  );
}